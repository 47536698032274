
























































import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import api from "@/api";
import {
  CityDto,
  ProvinceDto,
  SysSettingTaxeCreateOrUpdateDto,
  SysSettingTaxeDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "EditSysSettingTaxe",
})
export default class EditSysSettingTaxe extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  show = false;
  loading = true;
  form: SysSettingTaxeCreateOrUpdateDto = {};
  provinceList: ProvinceDto[] | undefined = [];
  citiesList: CityDto[] | undefined = [];
  defaultData: SysSettingTaxeDto = {
    id: 0,
  };
  linkRule = {
    provinceId: [
      {
        required: true,
        message: "请选择省",
        trigger: "blur",
      },
    ],
    cityId: [
      {
        required: true,
        message: "请选择市",
        trigger: "change",
      },
    ],
    taxesQST: [
      {
        required: true,
        message: "请输入QST",
        trigger: "change",
      },
    ],
    taxesGST: [
      {
        required: true,
        message: "请输入GST",
        trigger: "change",
      },
    ],
    remark: [
      {
        required: true,
        message: "请输入备注",
        trigger: "change",
      },
    ],
  };

  get title() {
    if (this.dataId) {
      return `编辑`;
    } else {
      return "新建";
    }
  }

  save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          api.sysSettingTaxe
            .update({
              body: this.form,
            })
            .then(() => {
              this.show = false;
              this.$message.success("更新成功");
            });
        } else {
          api.sysSettingTaxe
            .create({
              body: this.form,
            })
            .then(() => {
              this.show = false;
              this.$message.success("新建成功");
            });
        }
      }
    });
  }

  created() {
    this.getProvinceList();
  }

  async getProvinceList() {
    await api.province.getAll().then((res) => {
      this.provinceList = res!.items;
    });
  }

  @Watch("form.provinceId")
  getCitiesList() {
    if (this.form.provinceId != null) {
      // console.log("provinceId=" + this.form.provinceId);
      api.city
        .getAllList({
          provinceId: this.form!.provinceId,
        })
        .then((res) => {
          // console.log("citiesList=" + this.citiesList);
          this.citiesList = res!;
        });
    }
  }

  @Watch("show")
  onShowChange(value: boolean) {
    if (value) {
      if (this.dataId) {
        this.loading = true;
        api.sysSettingTaxe
          .get({ id: this.dataId })
          .then((res: SysSettingTaxeCreateOrUpdateDto) => {
            this.form = res!;
            console.log("form=" + this.form);
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.form = { ...this.defaultData };
        this.loading = false;
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  cancel() {
    this.show = false;
  }
}
